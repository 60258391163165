<template>
  <b-dropdown
    size="sm"
    variant="link"
    toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
    no-caret
    right
    no-flip
  >
    <template v-slot:button-content>
      <i class="ki ki-bold-more-ver"></i>
    </template>
    <!--begin::Navigation-->
    <div class="navi navi-hover" style="width: 250px">
      <b-dropdown-text tag="div" class="navi-item">
        <a @click="getExcel(index.id)" class="w-100 navi-link">
          <span class="navi-icon">
            <i class="flaticon2-file-1"></i>
          </span>
          <span class="navi-text">Export excel</span>
        </a>
      </b-dropdown-text>
    </div>
    <!--end::Navigation-->
  </b-dropdown>
</template>

<style lang="scss">
.dropdown-menu {
  z-index: 80;
}
.navi .navi-item .navi-link {
  padding: 0.75rem 1rem;
}
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import axios from 'axios'
export default {
  name: 'ZayavkaAction',
  props: {
    btnClass: String,
    index: Object,
    id: Number
  },
  components: {},
  methods: {
    getExcel(val) {
      axios
        .get('zayavkas/ttn/reporttoexcel/' + val + '/', {
          responseType: 'blob'
        })
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel'
            })
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'TTN')
          document.body.appendChild(link)
          link.click()
        })
    }
  },
  mounted() {}
}
</script>
