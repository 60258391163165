<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">{{ $t('BREADCRUMBS.TTN') }}</h3>
          </div>
        </div>

        <div class="card-body">
          <v-data-table
            :loading="isLoading"
            :headers="headers"
            :items="alltnn"
            no-data-text="Maʼlumot kiritilmagan"
            disable-pagination
            single-select
            @click:row="rowClick"
            item-key="id"
            hide-default-footer
            class="elevation-1 row-pointer"
          >
            <template v-slot:[`item.created_at`]="{ item }">
              {{ item.created_at | formatDate }}
            </template>
            <template v-slot:[`item.updated_at`]="{ item }">
              {{ item.updated_at | formatDate }}
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <tnnAction :index="item"></tnnAction> </template
          ></v-data-table>
          <div class="text-left my-5">
            <v-pagination
              v-model="currentPage"
              :total-visible="10"
              @input="getPostData(currentPage)"
              :length="Math.ceil(getCount / 10)"
            ></v-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import tnnAction from '@/view/content/dropdown/tnnAction.vue'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

export default {
  data() {
    return {
      currentPage: 1,
      counter: 0, // count the clicks
      timer: null, // Needs to be specified here so it can be accessed on both clicks
      selectedId: -1,
      perPage: 10
    }
  },
  created() {
    if (this.$route.query.page !== undefined) {
      this.getPostData(parseInt(this.$route.query.page, 10))
      this.currentPage = parseInt(this.$route.query.page, 10)
    } else {
      this.getPostData(this.currentPage)
    }
  },
  components: { tnnAction },
  computed: {
    getCount() {
      return this.$store.state.requests.alltnn.count
    },
    headers() {
      return [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: this.$t('TABLE_HEADER.NAME'),
          value: 'client_name'
        },
        {
          text: this.$t('TABLE_HEADER.CONTRACT_NUMBER'),
          value: 'contract_number'
        },
        {
          text: this.$t('TABLE_HEADER.REG_NUMBER'),
          value: 'reg_number'
        },
        {
          text: '“Kiritilgan”',
          value: 'created_at'
        },
        {
          text: '“Yangilangan”',
          value: 'updated_at'
        },
        {
          text: '',
          value: 'action',
          sortable: false
        }
      ]
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    alltnn() {
      return this.$store.state.requests.alltnn.results
    }
  },
  methods: {
    getPostData(value) {
      if (parseInt(this.$router.currentRoute.query.page, 10) !== value) {
        this.$router.push({
          path: this.$router.currentRoute.path,
          query: { page: value }
        })
      }
      this.$store.dispatch('getAllTnn', value)
    },
    rowClick: function (item, row) {
      var self = this
      this.counter++
      if (this.counter == 1) {
        this.timer = setTimeout(function () {
          self.counter = 0
          row.select(true)
          this.selectedId = item.id
        }, 200)
      } else {
        clearTimeout(this.timer)
        self.counter = 0
        this.$store.dispatch('getAllTnn')
        row.select(false)
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t('BREADCRUMBS.SOLD') },
      { title: this.$t('BREADCRUMBS.TTN') }
    ])
  }
}
</script>

<style scoped>
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  width: 100%;
}
</style>
